<template>
  <v-card class="d-flex flex-column" id="cardDash" elevation="0">
    <FilterEncapsulated
      ref="filter"
      :switches="filterSwitches"
      :filterState="filtersSaved"
      :disabled="isFiltersDisabled"
      :disabledFilterApplyBtn="disableApplyFilterButton"
      :showSwitch="currentDisabled"
      :countCedido="countTot"
      @applyFilter="applyFilter"
      @clearFilters="clearFiltersLocal"
      @toggleSwitch="toggleSwitchFilter"
      @closeFilterDialog="closeFilterDialog"
      :heightDialog="heightForDialog"
    >
      <template v-slot:filters>
        <SelectMultiple
          :hasSelectAll="true"
          id="selectOrigem"
          :label="txt.origem"
          v-model="filters.originCnpjList"
          :disabled="isFiltersDisabled"
          :items="selectCNPJOrigem"
        />
        <SelectMultiple
          :hasSelectAll="true"
          id="selectDestino"
          :label="txt.destino"
          v-model="filters.destinationCnpjList"
          :items="selectCNPJDestino"
          :disabled="isFiltersDisabled"
        />
        <SelectMultiple
          :hasSelectAll="true"
          id="selectRegPrevidenciario"
          :label="txt.regimePrevidenciario"
          :items="selectRegimePrevidenciario"
          v-model="filters.tpRegPrevList"
          :disabled="isFiltersDisabled"
        />

        <AGDatePickerRangeMonth
          id="datepickerPeriod"
          :label="txt.periodo"
          v-model="filters.periodo"
          :disabled="isFiltersDisabled"
        />
        <p class="titleForFilterSwitch">{{ txt.titleSituacao }}</p>
        <AGSwitch
          class="addPaddingToSwitch"
          :disabled="isFiltersDisabled"
          :id="switches[0].id"
          :key="switches[0].id"
          :name="switches[0].id + '_name'"
          :count="isCededCount"
          v-model="filters.isCeded"
          :label="switches[0].label"
          @input="toggleSwitch(switches[0])"
        />
        <AGSwitch
          class="addPaddingToSwitch"
          :disabled="isFiltersDisabled"
          :id="switches[1].id"
          :key="switches[1].id"
          :count="isNotCededCount"
          :name="switches[1].id + '_name'"
          v-model="filters.isNotCeded"
          :label="switches[1].label"
          @input="toggleSwitch(switches[1])"
        />
        <SelectSimple
          :disabled="isFiltersDisabled"
          :hasSelectAll="false"
          id="selectDeclaracao"
          :label="txt.declaracao"
          v-model="filters.originRemunDeclarationToggle"
          :items="OptionsDeclaração"
        />
      </template>
    </FilterEncapsulated>

    <GraphsContainer :title="txt.graphsContainerTitle" :helper="txt.helper" :disabled="loadingraphs" v-if="showGraph">
      <template v-slot:graphs> <BarGraph :graphData="dataForGraph" :loadingGraph="loadingraphs" /> </template>
    </GraphsContainer>

    <div class="cardTableAndGraphs">
      <div class="d-flex justify-space-between" style="padding-bottom: 16px">
        <CardByType v-for="item in cardTypes" :loading="loadingraphs" :key="item.title" :object="item" />
      </div>

      <v-col style="padding: 0px !important"> <TableCedidos></TableCedidos> </v-col>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'Cedidos',
  components: {
    BarGraph: () => import('../commons/BarGraphs.vue'),
    AGSwitch: () => import('../commons/AGSwitch.vue'),
    TableCedidos: () => import('./components/TableCedidos.vue'),
    FilterEncapsulated: () => import('../commons/FilterEncapsulated.vue'),
    AGDatePickerRangeMonth: () => import('../commons/AGDatePickerRangeMonth.vue'),
    SelectMultiple: () => import('../commons/SelectMultiple.vue'),
    SelectSimple: () => import('../commons/SelectSimple.vue'),
    GraphsContainer: () => import('../commons/GraphsContainer.vue'),
    CardByType: () => import('./components/CardCessaoType.vue'),
  },

  data() {
    return {
      heightForDialog: '650px',
      title: 'Servidores',
      subtitle: 'Monitoramento das informações do servidor',
      showGraph: true,
      itemsBreadCrumb: [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Esocial',
          disabled: true,
          href: 'todos',
        },
        {
          title: 'Cessão',
          disabled: false,
          href: 'cedidos',
        },
      ],
      OptionsDeclaração: [
        { itemTitle: 'Com declaração', itemId: true, value: true },
        { itemTitle: 'Sem declaração', itemId: false, value: false },
      ],
      filters: {
        originCnpjList: [],
        destinationCnpjList: [],
        tpRegPrevList: [],
        periodo: [],
        originRemunDeclarationToggle: null,
        isCeded: false,
        isNotCeded: false,
        isCededOnCurrentDate: false,
      },
      txt: {
        origem: 'Origem',
        destino: 'Destino',
        regimePrevidenciario: 'Regime previdenciário',
        periodo: 'Periodo',
        titleSituacao: 'Situação de cessão',
        graphsContainerTitle: 'Histórico de remuneração no órgão de origem por período',
        declaracao: 'Declaração de remuneração no órgâo de origem',
        helper:
          'Este gráfico pode receber filtros por diferentes critérios, incluindo: período, situação de cessão, regime previdenciário, origem e destino.',
      },

      switches: [
        {
          id: 'switch_cedidos',
          label: 'Está cedido',
          value: true,
          disabled: false,
        },
        {
          id: 'switch_naoCedidos',
          label: 'Não está cedido',
          value: false,
          disabled: false,
        },
      ],
      disableApplyFilterButton: true,
    };
  },

  computed: {
    ...mapGetters({
      countTot: 'esocial/esocialCedidos/getTotCollaboratorsCurrent',
      currentDisabled: 'esocial/esocialCedidos/getCurrentDisabled',
      isNotCededCount: 'esocial/esocialCedidos/getCountIsNotCeded',
      isCededCount: 'esocial/esocialCedidos/getCountCeded',
      usuario: 'user/getUserDetails',
      userInfo: 'user/userInfo',
      perfis: 'user/userAuthorities',
      userInfo: 'user/userInfo',
      companiesList: 'egov/getCompaniesList',
      filtersSaved: 'esocial/esocialCedidos/getFiltersSaved',
      defaultFilterState: 'esocial/esocialCedidos/getDefaultFilterState',
      loadingraphs: 'esocial/esocialCedidos/getLoadingGraphs',
      loadingFilter: 'esocial/esocialCedidos/getLoadingFilters',
      cardOneStatusFromVue: 'esocial/esocialCedidos/getCardOneDisabled',
      cardTwoStatusFromVue: 'esocial/esocialCedidos/getCardTwoDisabled',
      cardThreeStatusFromVue: 'esocial/esocialCedidos/getCardThreeDisabled',
      dataForGraph: 'esocial/esocialCedidos/getDataForGraph',
      dataForCardGraph: 'esocial/esocialCedidos/getCardGraphData',
      selectCNPJ: 'esocial/esocialCedidos/getSelectCnpj',
      selectRegimePrevidenciario: 'esocial/esocialCedidos/getSelectRegPrev',
      selectCNPJDestino: 'esocial/esocialCedidos/getSelectDestinationCnpjList',
      selectCNPJOrigem: 'esocial/esocialCedidos/getSelectOriginCnpjList',
    }),
    isFiltersDisabled() {
      return this.loadingFilter;
    },
    cardTypes() {
      return this.dataForCardGraph;
    },
    filterSwitches() {
      let switches = [
        {
          id: 'switch_cedidosDataCorrente',
          label: 'Cedidos na data corrente',
          value: this.filtersSaved.isCededOnCurrentDate,
          disabled: this.currentDisabled,
        },
      ];
      return switches;
    },
    isGraphDisabled() {
      return [undefined, null].includes(this.dataForGraph);
    },
  },

  async mounted() {
    this.init(this.userInfo.id);
  },
  methods: {
    ...mapActions('esocial/esocialCedidos', ['clearFilters', 'applyFilters', 'fetchCedidosCurrentDate', 'init']),

    ...mapActions('user', ['getUser']),

    applyFilter() {
      this.applyFilters(this.filters);
    },
    toggleSwitchFilter(item) {
      this.fetchCedidosCurrentDate(item.value);
    },
    clearFiltersLocal() {
      this.clearFilters();
    },
    toggleSwitch(item) {
      if (item.id === 'switch_naoCedidos') {
        if (this.filters.isNotCeded === true) {
          this.filters.isNotCeded = true;
          this.switches[1].value = false;
          this.filters.isCeded = false;
        } else {
          this.filters.isNotCeded = false;
          this.switches[1].value = false;
          this.filters.isCeded = false;
        }
      } else if (item.id === 'switch_cedidos') {
        if (this.filters.isCeded === true) {
          this.filters.isNotCeded = false;
          this.switches[0].value = false;
          this.filters.isCeded = true;
        } else {
          this.filters.isNotCeded = false;
          this.switches[0].value = false;
          this.filters.isCeded = false;
        }
      }
    },
    closeFilterDialog() {
      this.filters = cloneDeep(this.filtersSaved);
    },
  },

  watch: {
    filters: {
      handler(newFilterValue) {
        let defaultFilters = _.cloneDeep(this.defaultFilterState);
        delete defaultFilters.filterTerm;
        if (JSON.stringify(newFilterValue) === JSON.stringify(defaultFilters)) {
          this.disableApplyFilterButton = true;
        } else {
          this.disableApplyFilterButton = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/variables.scss';

.cardDash {
  width: 1792px;
  max-width: 1792px;
}

.cardTableAndGraphs {
  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid $neutral-color-low-dark;
}

.titleForFilterSwitch {
  padding-top: $spacing-05;
  font-family: $font-family-base;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-bottom: 0;
}
.addPaddingToSwitch {
  padding-top: 2px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.filters-dropdown-content {
  div {
    padding-top: $spacing-05;
  }
}
::v-deep .filters-dropdown {
  max-height: 900px;
  height: 900px;
}
</style>
