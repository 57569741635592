var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column",
      attrs: { id: "cardDash", elevation: "0" },
    },
    [
      _c("FilterEncapsulated", {
        ref: "filter",
        attrs: {
          switches: _vm.filterSwitches,
          filterState: _vm.filtersSaved,
          disabled: _vm.isFiltersDisabled,
          disabledFilterApplyBtn: _vm.disableApplyFilterButton,
          showSwitch: _vm.currentDisabled,
          countCedido: _vm.countTot,
          heightDialog: _vm.heightForDialog,
        },
        on: {
          applyFilter: _vm.applyFilter,
          clearFilters: _vm.clearFiltersLocal,
          toggleSwitch: _vm.toggleSwitchFilter,
          closeFilterDialog: _vm.closeFilterDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "filters",
            fn: function () {
              return [
                _c("SelectMultiple", {
                  attrs: {
                    hasSelectAll: true,
                    id: "selectOrigem",
                    label: _vm.txt.origem,
                    disabled: _vm.isFiltersDisabled,
                    items: _vm.selectCNPJOrigem,
                  },
                  model: {
                    value: _vm.filters.originCnpjList,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "originCnpjList", $$v)
                    },
                    expression: "filters.originCnpjList",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    hasSelectAll: true,
                    id: "selectDestino",
                    label: _vm.txt.destino,
                    items: _vm.selectCNPJDestino,
                    disabled: _vm.isFiltersDisabled,
                  },
                  model: {
                    value: _vm.filters.destinationCnpjList,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "destinationCnpjList", $$v)
                    },
                    expression: "filters.destinationCnpjList",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    hasSelectAll: true,
                    id: "selectRegPrevidenciario",
                    label: _vm.txt.regimePrevidenciario,
                    items: _vm.selectRegimePrevidenciario,
                    disabled: _vm.isFiltersDisabled,
                  },
                  model: {
                    value: _vm.filters.tpRegPrevList,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "tpRegPrevList", $$v)
                    },
                    expression: "filters.tpRegPrevList",
                  },
                }),
                _c("AGDatePickerRangeMonth", {
                  attrs: {
                    id: "datepickerPeriod",
                    label: _vm.txt.periodo,
                    disabled: _vm.isFiltersDisabled,
                  },
                  model: {
                    value: _vm.filters.periodo,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "periodo", $$v)
                    },
                    expression: "filters.periodo",
                  },
                }),
                _c("p", { staticClass: "titleForFilterSwitch" }, [
                  _vm._v(_vm._s(_vm.txt.titleSituacao)),
                ]),
                _c("AGSwitch", {
                  key: _vm.switches[0].id,
                  staticClass: "addPaddingToSwitch",
                  attrs: {
                    disabled: _vm.isFiltersDisabled,
                    id: _vm.switches[0].id,
                    name: _vm.switches[0].id + "_name",
                    count: _vm.isCededCount,
                    label: _vm.switches[0].label,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.toggleSwitch(_vm.switches[0])
                    },
                  },
                  model: {
                    value: _vm.filters.isCeded,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "isCeded", $$v)
                    },
                    expression: "filters.isCeded",
                  },
                }),
                _c("AGSwitch", {
                  key: _vm.switches[1].id,
                  staticClass: "addPaddingToSwitch",
                  attrs: {
                    disabled: _vm.isFiltersDisabled,
                    id: _vm.switches[1].id,
                    count: _vm.isNotCededCount,
                    name: _vm.switches[1].id + "_name",
                    label: _vm.switches[1].label,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.toggleSwitch(_vm.switches[1])
                    },
                  },
                  model: {
                    value: _vm.filters.isNotCeded,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "isNotCeded", $$v)
                    },
                    expression: "filters.isNotCeded",
                  },
                }),
                _c("SelectSimple", {
                  attrs: {
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: false,
                    id: "selectDeclaracao",
                    label: _vm.txt.declaracao,
                    items: _vm.OptionsDeclaração,
                  },
                  model: {
                    value: _vm.filters.originRemunDeclarationToggle,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "originRemunDeclarationToggle", $$v)
                    },
                    expression: "filters.originRemunDeclarationToggle",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showGraph
        ? _c("GraphsContainer", {
            attrs: {
              title: _vm.txt.graphsContainerTitle,
              helper: _vm.txt.helper,
              disabled: _vm.loadingraphs,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "graphs",
                  fn: function () {
                    return [
                      _c("BarGraph", {
                        attrs: {
                          graphData: _vm.dataForGraph,
                          loadingGraph: _vm.loadingraphs,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1609996511
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cardTableAndGraphs" },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between",
              staticStyle: { "padding-bottom": "16px" },
            },
            _vm._l(_vm.cardTypes, function (item) {
              return _c("CardByType", {
                key: item.title,
                attrs: { loading: _vm.loadingraphs, object: item },
              })
            }),
            1
          ),
          _c(
            "v-col",
            { staticStyle: { padding: "0px !important" } },
            [_c("TableCedidos")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }